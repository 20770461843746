@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Pacifico");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #11131b;
  color: #fff;
  padding: 0;
  margin: 0;
  font-weight: 200;
  min-height: 99vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
