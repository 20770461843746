input {
  font-size: 20px;
  width: 80%;
}

select {
  font-size: 20px;
  width: 82%;
}

.App {
  margin-top: 10px;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.center {
  text-align: center;
}

.btn-change-coffeespace {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  background-color: #363a49;
  width: 20%;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 0px 0px 0px 10px;
  cursor: pointer;
}

.btn-change-coffeespace:hover {
  background-color: #2c2f3a;
}

.btn-dame-cafe {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  background-color: #238636;
  width: 75%;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 15px 15px;
  cursor: pointer;
}

.btn-dame-cafe:hover {
  background-color: #1c6b2b;
}

.btn-elimina-cafe {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  background-color: #e9576f;
  width: 50%;
  width: 50%;
  width: 50%;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 15px 15px 15px 0;
  cursor: pointer;
}

.btn-elimina-cafe:hover {
  background-color: #ba4659;
}

.box-content {
  margin-bottom: 10px;
  background-color: #1a1d28;
  max-width: 600px;
  width: 88%;
  border-radius: 24px;
  padding: 10px 20px;
}

.warning {
  max-width: 500px;
  border: 1px solid #e9576f;
  padding: 5px 10px;
  border-radius: 10px;
  color: #e9576f;
  font-size: 11px;
}

.success {
  max-width: 500px;
  border: 1px solid #238636;
  padding: 5px 10px;
  border-radius: 10px;
  color: #e9576f;
  font-size: 11px;
}

.coffee-list {
  list-style: none;
  width: 100%;
  padding: 0px;
  min-height: 30vh;
}

.coffee-item {
  --tw-border-opacity: 0.1;
  min-height: 70px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 5px;
  position: relative;
}

.coffee-item:hover {
  border: 1px solid white;
}

.coffee-group span {
  color: #6a748e;
  font-size: 18px;
  text-align: center;
  display: block;
  min-width: 100px;
}

.coffee-group h2 {
  font-size: 15px;
  font-weight: 500;
}

.coffe-type {
  color: #6a748e;
}

.coffee-text-area {
  font-size: 15px;
  width: 85%;
  height: 75px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.coffee-app-logo {
  width: 120px;
}

.coffee-app-title {
  margin-top: 10px;
  font-family: "Pacifico", cursive;
  font-weight: 500;
  font-size: 50px;
  animation: 2s pulsate infinite;
  margin-bottom: 15px;
}

.coffee-app-title-exclamation {
  -webkit-text-stroke: 0.7px white;
  -webkit-text-fill-color: #6f4e37;
}

.coffe-app-data {
  flex: 40%;
}

.coffe-form-data {
  flex: 60%;
}

.footer {
  bottom: 0px;
  right: 0;
  left: 0;
  text-align: center;
  margin-bottom: 20px;
}

.devby {
  color: #6a748e;
  font-size: 10px;
  text-decoration: none;
  font-weight: 200;
}

.devby:hover {
  -webkit-transition: ease-out all 0.2s;
  transition: ease-out all 0.2s;
  text-decoration: underline;
  font-size: 15px;
}

@keyframes pulsate {
  50% {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 440px) {
  .coffee-app-logo {
    width: 60px;
    padding-left: 20px;
  }

  .coffee-app-title {
    display: inline;
  }

  @keyframes pulsate {
  }
}
